import React, { useState, useEffect } from 'react';
import { FloatCard, ModalMackdrop } from './styles';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { ReactComponent as TelephoneIcon } from '../../assets/espaco_gerente_options/tegra-icon-telephone.svg';
import { ReactComponent as EmailIcon } from '../../assets/espaco_gerente_options/tegra-icon-email.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/tegra-icon-arrow-down.svg';
import { isParcerias } from '../../utils/logo';
import userTie from '../../assets/user-tie-solid.svg';


interface IContactFloatCardProductProps {
  id_produto: number;
}

const ContactFloatCardProduct: React.FC<IContactFloatCardProductProps> = (produto) => {
  const { user } = useAuth();
  const [idProduto, setIdProduto] = useState<number>(0);
  const [supervisores, setSupervisores] = useState<any[]>([]); //Lista de Supervisores
  const [aberto, setAberto] = useState<boolean>(
    localStorage.getItem('__popup') === '1',
  );

  useEffect(() => {    
    async function loadSupervisorProduto(){
      if (produto.id_produto !== null && produto.id_produto !== undefined && idProduto != produto.id_produto){
        setIdProduto(produto.id_produto);
        const response = await api.get<any[]>(
          `Contato/supervisorProduto?produto=${produto.id_produto}`,
        );
        setSupervisores(response.data);
      } 
    }     
    loadSupervisorProduto();
  },[produto]);
  
  const togglePopup = (e: any): void => {
    e.preventDefault();
    localStorage.setItem('__popup', aberto ? '0' : '1');
    setAberto(!aberto);
  };

  return (
    <>
        {/*!isParcerias()  && */supervisores.length > 0 && (          
        <>
          <ModalMackdrop onClick={() => setAberto(false)}/>
          <FloatCard className={aberto ? 'aberto' : supervisores.length >= 3 ?  'fechado3' : supervisores.length == 2 ? 'fechado2' : 'fechado1'}
            style={{ maxHeight: (supervisores.length >= 3 ? '515px' : '350px')}}>
            <button
              type="button"
              className="FloatCard__header"
              onClick={togglePopup}
            >
              Fale com os(as) especialistas do produto:
              <div className='speech-bubble'><img src={userTie} alt="userTie" /></div>
              
              <ArrowDownIcon />

            </button>
            <div className='ContainerCards' style={supervisores.length > 3 ? {height:'470px', overflowY:'scroll', position:'relative'} : {}}>
              {
                supervisores.map(item => (
                  <>
                    <div  className="FloatCard__infos">
                      {/*
                      <div className="FloatCard__infosTitle">
                        <h6 className="FloatCard__infosTitleText">Fale com o {item.tx_area}</h6>
                      </div>
                      */}
                      <div className="FloatCard__infosName">
                        <span
                          className="FloatCard__infosNamePic"
                          style={{backgroundImage: `url(${item.foto}` }}
                        />
                        <div>
                          <p className="FloatCard__infosNameText">
                            {item.tx_nome}
                          </p>
                          <a
                            href={`mailto:${item.tx_email}`}
                            className="FloatCard__infosEmailText"
                          >
                            {item.tx_email}
                          </a>
                          <a
                            href={`tel:+55${item.tx_telefone}`}
                            className="FloatCard__infosPhoneText"
                          >
                            {item.tx_telefone}
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              }

            </div>
          </FloatCard>        
        </>

        )}


    </>
  );
};

export default ContactFloatCardProduct;
